
import { defineComponent, onBeforeUnmount, onUnmounted, ref } from "vue";
import TopBar from "@/components/TopBar.vue";
import Footer from "@/components/Footer.vue";
import productsBox from "@/components/productsBox.vue";
import BackTop from "@/components/BackTop.vue";
// import CookieBox from "@/components/CookieBox.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { getMenu, getRecommendList } from '@/api/contact'
import { Search } from '@element-plus/icons-vue'
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { usePinia } from "@/store";
import { storeToRefs } from "pinia";
export default defineComponent({
  name: "Products",
  components: {
    TopBar,
    Footer,
    ChatBox: productsBox,
    BackTop,
    // CookieBox,
    Breadcrumb
  },
  metaInfo: {
    title: "B2B Marketplace - Chemicals & Steel - OTIMES",
    meta: [
      {
        name: "description",
        content: "Otimes product introduction",
      },
      {
        name: "keywords",
        content:
          "OTIMES,PLA,PC,POM,PA,ABS,PP,Engineering Plastics,PLASTIC MATERIALS,STEEL,Cold Rolled Steel,Silicon Steel",
      },
    ],
  },
  setup () {
    const store = usePinia()
    const input = ref('')
    const route = useRoute()
    const router = useRouter()
    const { detail, active, menuName } = storeToRefs(store) as any
    const loading = ref(false)
    menuName.value = route.query.type

    //钢材，塑料切换
    const activeObj = {
      'PLASTICS MATERIAL': 0,
      'STEELS MATERIAL': 1
    }
    const breadcrumbList = ref([active.value])
    const activeTab = name => {
      active.value = name
      breadcrumbList.value = [name]
      menuName.value = ''
      // scrollY.value = window.scrollY
      getMenuAsync()
      getRecommendListAsync()
      router.push({ name: 'PRODUCTS' })
    }
    //去往=详情
    const toDetail = ele => {
      menuName.value = ele.category
      detail.value = ele
      // scrollY.value = window.scrollY
      router.push({
        name: 'similar-detail',
        query: {
          id: ele.id,
          category: ele.category,
          essentialDetailsKey: ele.essentialDetailsKey
        }
      })
    }

    const aHref = (v) => {
      return `/products/similar-detail.html?id=${v.id}&category=${v.category}&essentialDetailsKey=${v.essentialDetailsKey}`
    }

    //菜单
    const menuList = ref([])
    const clickMenu = name => {
      breadcrumbList.value[1] = name
      menuName.value = name
      router.push({
        name: 'similar-goods',
        query: { type: name }
      })
    }

    const page = ref(1)
    const count = ref(0)
    let bigPage = 0;
    const pre = () => {
      if (page.value === 1) {
        ElMessage.error("It's already the first page")
      } else {
        page.value--
        getRecommendListAsync()
      }
    }
    const next = () => {
      if (page.value + 1 > bigPage) {
        ElMessage.error("It's already the last page!")
      } else {
        page.value++
        getRecommendListAsync()
      }
    }

    //推荐列表
    const recommendList = ref([])
    const getRecommendListAsync = async () => {
      const res = await getRecommendList({ group: activeObj[active.value], page: page.value }) as any
      if (res) {
        recommendList.value = res.data.data
        count.value = res.data.total
        bigPage = Math.ceil(count.value / 4)
      } else {
        ElMessage.error(res.message)
      }
    }

    //获取数据
    const getMenuAsync = async () => {
      loading.value = true
      const res = await getMenu({ group: activeObj[active.value] }) as any
      if (res) {
        menuList.value = res.data
      } else {
        ElMessage.error(res.message)
      }
      loading.value = false
    }
    getMenuAsync()
    getRecommendListAsync()

    //轮询图片分割 
    const splitImg = (imgs): any => {
      try {
        const list = imgs.split(',')
        return list[1]
      } catch (err) {
        console.log('分割图片出错了');
      }
    }
    return {
      loading,
      aHref,
      pre,
      next,
      breadcrumbList,
      splitImg,
      active,
      activeTab,
      menuList,
      menuName,
      clickMenu,
      input,
      Search,
      recommendList,
      toDetail
    };
  },
});
